.home-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
}

/* Optional background color for the entire page */
body {
  background-color: #f8f9fa;
}

.no-content-message {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 25px 15px;
  margin: 20px 15px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.no-content-message p {
  margin: 0;
  color: #6c757d;
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .home-container {
    padding: 0 10px;
  }
}

/* For larger screens */
@media (min-width: 768px) {
  .home-container {
    max-width: 750px;
  }
}