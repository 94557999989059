.cjc-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.cjc-btn-group {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 12px;
    width: 100%;
}

.cjc-toolbar-label {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cjc-toolbar button {
    color: #333;
    background-color: white;
    border: 1px solid #ddd;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 500;
    transition: all 0.2s ease;
    flex: 1;
    max-width: 100px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cjc-toolbar button:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
    transform: translateY(-1px);
}

.cjc-toolbar button:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Mobile styling */
@media (max-width: 480px) {
    .cjc-toolbar {
        padding: 8px 0;
    }

    .cjc-btn-group {
        width: 95%;
    }

    .cjc-toolbar button {
        padding: 6px 12px;
        font-size: 14px;
    }

    .cjc-toolbar-label {
        font-size: 16px;
    }
}