.event-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 5px;
  scrollbar-width: thin;
  scrollbar-color: #40B3A2 #f1f1f1;
}

.event-list::-webkit-scrollbar {
  width: 6px;
}

.event-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.event-list::-webkit-scrollbar-thumb {
  background: #40B3A2;
  border-radius: 10px;
}

.event-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  cursor: pointer;
  border-left: 3px solid transparent;
}

.event-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.12);
}

.event-card:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.event-title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.event-time-only {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.event-card h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  line-height: 1.2;
}

.event-card p {
  font-size: 0.85rem;
  margin: 0;
  color: #666;
}

.event-time {
  font-weight: 500;
  color: #40B3A2;
}

.event-class-type {
  font-size: 0.75rem;
  color: #888;
  margin-top: 2px;
}

.capitalized {
  text-transform: capitalize;
}

.card-icon {
  margin-right: 12px;
  font-size: 1.2em;
  color: #40B3A2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  height: 25px;
  width: 1px;
  background-color: #e0e0e0;
  margin-right: 12px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  color: #666;
  font-style: italic;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .event-card {
    padding: 10px 12px;
  }
  
  .event-card h3 {
    font-size: 0.95rem;
  }
  
  .event-card p {
    font-size: 0.8rem;
  }
  
  .card-icon {
    margin-right: 8px;
    font-size: 1em;
  }
  
  .divider {
    margin-right: 8px;
  }
}