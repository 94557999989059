.hours-worked-cards-list {
  padding: 5px 0;
  margin: 0;
}

.no-hours-message {
  text-align: center;
  padding: 30px 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  margin: 15px 0;
  color: #6c757d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-hours-message h3 {
  margin: 10px 0;
  color: #343a40;
}

.no-hours-message p {
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 0;
}

.no-hours-icon {
  font-size: 36px;
  color: #3f72af;
  margin-bottom: 5px;
}

/* Mobile styling */
@media (max-width: 480px) {
  .hours-worked-cards-list {
    padding: 0;
  }
  
  .no-hours-message {
    padding: 20px 15px;
  }
  
  .no-hours-message p {
    font-size: 0.9rem;
  }
}