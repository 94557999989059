.user-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* This makes sure the content is vertically centered in the viewport */
  padding: 20px;
  background: #f4f7f6;
}

.user-detail-card {
    width: 300px;
    padding: 20px;
    box-shadow: 0 8px 20px rgba(0,0,0,0.08);
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .user-detail-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 25px rgba(0,0,0,0.12);
  }
  
  .profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .profile-placeholder {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ddd;
    margin-bottom: 10px;
  }
  
  .name {
    font-size: 22px;
    font-weight: 600;
    color: #333;
    margin: 15px 0 5px 0;
  }
  
  .admin-toggle {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #3498db;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #3498db;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .status-label {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #555;
  }
  
.back-button {
  padding: 10px 20px;
  border: none;
  background-color: #3498db;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.back-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.back-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}

/* Mobile responsiveness */
@media (max-width: 379px) {
  .user-detail-card {
    width: 90%;
    padding: 15px;
  }
  
  .name {
    font-size: 20px;
  }
  
  .back-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}