.daily-coach-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  padding-top: 15px;
  position: relative;
}

.daily-coach-video::after {
  content: '';
  width: 90%;
  height: 1px;
  background: #e0e0e0;
  position: absolute;
  bottom: -15px;
  left: 5%;
}

.daily-coach-video h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 550px;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 8px 20px -8px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 25px -8px rgba(0, 0, 0, 0.35);
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: none;
}