.total-hours-counter {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    text-align: center;
}

.total-hours-counter h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}

.total-hours-counter .hours-value {
    font-size: 1.8rem;
    font-weight: 700;
    color: #2c3e50;
    margin: 0;
    display: block;
}

/* Mobile styling */
@media (max-width: 480px) {
    .total-hours-counter {
        padding: 8px;
        margin: 8px 0 12px;
    }
    
    .total-hours-counter h2 {
        font-size: 1.1rem;
    }
    
    .total-hours-counter .hours-value {
        font-size: 1.6rem;
    }
}