.container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.header h4 {
  margin: 0;
  color: #333;
}

.selectionInfo {
  font-size: 0.9rem;
  color: #666;
  background-color: #f8f9fa;
  padding: 4px 8px;
  border-radius: 4px;
}

.userList {
  max-height: 250px;
  overflow-y: auto;
  padding-right: 5px;
}

.userList::-webkit-scrollbar {
  width: 6px;
}

.userList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.userList::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

.userList::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.userCard {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 6px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: all 0.2s ease;
}

.userCard:hover:not(.disabled) {
  background-color: #e9ecef;
}

.userCard.selected {
  background-color: #e5f6ff;
  border: 1px solid #b3e0ff;
}

.userCard.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #f0f0f0;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
  flex-shrink: 0;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userInfo {
  flex: 1;
  min-width: 0;
}

.userName {
  font-weight: 500;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userEmail {
  font-size: 0.8rem;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subscriptionStatus, .notSubscribed {
  font-size: 0.8rem;
  padding: 2px 6px;
  border-radius: 12px;
  margin-left: 8px;
  white-space: nowrap;
}

.subscriptionStatus {
  background-color: #e6f7e6;
  color: #2e7d32;
}

.notSubscribed {
  background-color: #f5f5f5;
  color: #9e9e9e;
}

.noUsers {
  padding: 20px;
  text-align: center;
  color: #777;
  font-style: italic;
}

@media (max-width: 576px) {
  .userList {
    max-height: 200px;
  }
  
  .userCard {
    padding: 8px;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  
  .userEmail {
    display: none;
  }
}