.shift-notification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 15px 20px;
  position: relative;
}

.next-shift-notification {
  width: 100%;
  max-width: 450px;
  padding: 15px !important;
  background-color: #0B57A3;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0 6px 20px -5px rgba(11, 87, 163, 0.4);
  transition: all 0.3s ease;
  margin-bottom: 5px;
  cursor: pointer;
}

.next-shift-notification p {
  margin-bottom: 0px !important;
  font-weight: 500;
}

.next-shift-notification:hover {
  box-shadow: 0 8px 25px -2px rgba(11, 87, 163, 0.5);
  transform: translateY(-2px);
}

.next-shift-notification:active {
  transform: scale(0.98);
  box-shadow: 0 4px 10px -2px rgba(11, 87, 163, 0.4);
}

.next-shift-notification .next-shift-text {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 6px;
  font-weight: 600;
}

.dropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease;
  color: #fff;
  width: 100%;
  opacity: 0;
  border-top: 0px solid rgba(255, 255, 255, 0.2);
}

.dropdown.open {
  max-height: 150px;
  opacity: 1;
  padding-top: 8px;
  margin-top: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.dropdown p {
  text-align: center;
  padding: 8px 0;
  margin: 0;
  font-size: 0.95rem;
}

.dropdown p:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}