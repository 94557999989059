.recent-comments-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 15px;
  padding: 15px;
  position: relative;
  background-color: #f9f9f9;
  border-radius: 15px;
  box-shadow: 0 8px 20px -8px rgba(0, 0, 0, 0.2);
}

.recent-comments-container h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

/* Comment cards within the container */
.recent-comments-container div {
  background-color: white;
  padding: 15px;
  margin: 8px 0;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  width: 100%;
  transition: transform 0.2s ease;
}

.recent-comments-container div:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.comments-button {
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  padding: 12px 0;
  text-align: center;
  background-color: #0B57A3 !important;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(11, 87, 163, 0.25);
  transition: all 0.3s ease;
}

.comments-button:hover {
  background-color: #0a4c8e !important;
  box-shadow: 0 6px 15px rgba(11, 87, 163, 0.35);
  transform: translateY(-2px);
}

.comments-button:active {
  transform: translateY(0) scale(0.98);
  box-shadow: 0 2px 8px rgba(11, 87, 163, 0.2);
}

/* Full comments modal */
.coach-comments-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  padding: 20px;
  box-sizing: border-box;
  transform: translateY(100%);
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
}

.coach-comments-section.open {
  transform: translateY(0);
}

.comment-container {
  height: 80%;
}

.comments {
  height: 75%;
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 15px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 15px;
}

.comments::-webkit-scrollbar {
  display: none;
}

.comment {
  background: white;
  padding: 15px;
  margin: 10px 0;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #555;
  transition: color 0.2s ease;
  z-index: 10;
}

.close-button:hover {
  color: #333;
}

.new-comment-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 20px;
  background-color: #0B57A3;
  color: white;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  z-index: 1000;
}

.new-comment-notification:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.25);
}

.new-comment-notification:active {
  transform: scale(0.98);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}