@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.add-class-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
}

.page-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}

.page-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #40B3A2;
  border-radius: 2px;
}

.subtitle {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-top: 20px;
}

.card-container {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.option-card {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.option-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
}

.card-header {
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eaeaea;
  transition: all 0.3s ease;
}

.card-header-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.card-icon {
  font-size: 1.4rem;
  color: #40B3A2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(64, 179, 162, 0.1);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.toggle-icon {
  font-size: 1.2rem;
  color: #666;
  transition: all 0.3s ease;
}

.card-body {
  /* max-height: 0; */
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #fff;
  /* opacity: 0; */
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Card expanded states */
.option-card.selected .card-header {
  background-color: #40B3A2;
}

.option-card.selected .card-title,
.option-card.selected .toggle-icon {
  color: white;
}

.option-card.selected .card-icon {
  color: #40B3A2;
  background-color: white;
}

.option-card.selected .card-body {
  max-height: 1000px;
  opacity: 1;
}

.option-card.selected .toggle-icon {
  transform: rotate(180deg);
}

/* Delete card specific styling */
.option-card.delete-card .card-header {
  border-left: 4px solid #ff4d4d;
}

.option-card.delete-card.selected .card-header {
  background-color: #ff4d4d;
}

.option-card.delete-card .card-icon {
  color: #ff4d4d;
  background-color: rgba(255, 77, 77, 0.1);
}

.option-card.delete-card.selected .card-icon {
  color: #ff4d4d;
  background-color: white;
}

/* Form inputs styling */
.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.form-control {
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  color: #333;
  width: 100%;
}

.form-control:focus {
  outline: none;
  border-color: #40B3A2;
  box-shadow: 0 0 0 2px rgba(64, 179, 162, 0.2);
  background-color: #fff;
}

.form-label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
}

select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 15px;
  padding-right: 40px;
}

.weekday-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  justify-content: space-between;
}

.day-button {
  flex: 1 1 calc(14% - 8px);
  aspect-ratio: 1/1;
  border: none;
  border-radius: 50%;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #e9ecef;
  color: #555;
}

.day-button.selected {
  background-color: #40B3A2;
  color: white;
  box-shadow: 0 2px 8px rgba(64, 179, 162, 0.4);
}

.day-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.submit-button {
  background-color: #40B3A2;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.submit-button:hover {
  background-color: #369c8d;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(64, 179, 162, 0.4);
}

.submit-button:active {
  transform: translateY(0);
}

.delete-button {
  background-color: #ff4d4d;
}

.delete-button:hover {
  background-color: #e63e3e;
  box-shadow: 0 4px 12px rgba(255, 77, 77, 0.4);
}

/* Event list styling */
.events-container {
  width: 100%;
  margin-top: 15px;
  border-radius: 8px;
  overflow: hidden;
  max-height: 400px;
  overflow-y: auto;
}

/* Animation */
.animate-in {
  animation: fadeUpIn 0.5s ease forwards;
}

@keyframes fadeUpIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .add-class-page {
    padding: 15px;
  }
  
  .card-container {
    max-width: 100%;
  }
  
  .weekday-picker {
    gap: 5px;
  }
  
  .day-button {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .page-title {
    font-size: 1.5rem;
  }
  
  .card-header {
    padding: 12px 15px;
  }
  
  .card-content {
    padding: 15px;
  }
  
  .day-button {
    flex: 1 1 calc(14% - 6px);
    font-size: 0.75rem;
  }
}