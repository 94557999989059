.day-worked-card {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 15px;
  margin: 12px 0;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.day-worked-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.hourglass-card-icon {
  color: #3f72af;
  font-size: 24px;
  margin-right: 8px;
}

.divider {
  width: 1px;
  height: 50px;
  background-color: #e9ecef;
  margin: 0 15px;
}

.hours-content {
  flex: 1;
}

.hours-content h3 {
  margin: 0 0 5px 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.hours-content p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 700;
  color: #3f72af;
}

.hours-details {
  font-size: 0.8rem;
  color: #6c757d;
  margin-top: 5px;
}

.day-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #3f72af;
}

/* Mobile styling */
@media (max-width: 480px) {
  .day-worked-card {
    padding: 12px;
    margin: 10px 0;
  }
  
  .hourglass-card-icon {
    font-size: 20px;
  }
  
  .divider {
    height: 40px;
    margin: 0 12px;
  }
  
  .hours-content h3 {
    font-size: 1rem;
  }
  
  .hours-content p {
    font-size: 1.1rem;
  }
}