.weekly-workout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 15px;
  position: relative;
}

.weekly-workout-container::after {
  content: '';
  width: 90%;
  height: 1px;
  background: #e0e0e0;
  position: absolute;
  bottom: -15px;
  left: 5%;
}

.weekly-workout-title {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.weekly-workout-pdf-link {
  display: flex;
  justify-content: center;
  width: 75%;
  max-width: 350px;
  margin: 0 auto 30px;
}

.pdf-cover-image {
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 8px 20px -8px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.pdf-cover-image:hover {
  transform: translateY(-3px) scale(1.02);
  box-shadow: 0 12px 25px -8px rgba(0, 0, 0, 0.35);
}

.pdf-cover-image:active {
  transform: translateY(0) scale(0.98);
}