.programmingUploadPageContainer {
    padding: 20px;
    background: #f4f7f6;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 20px;
    color: #2c3e50;
    position: relative;
    padding-bottom: 8px;
}

.title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 2px;
    background-color: #3498db;
    border-radius: 1px;
}

.uploadCard {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.uploadSection {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.sectionTitle {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #333;
    font-weight: 500;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fileInput {
    display: none;
}

.fileLabel {
    display: inline-block;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-bottom: 10px;
}

.fileLabel:hover {
    background: #0056b3;
}

.uploadButton {
    display: inline-block;
    padding: 8px 16px; /* Adjusted padding for a smaller button */
    background: #6c757d; /* Changed to a gray color */
    /* padding: 10px 20px;
    background: #28a745; */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.uploadButton:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.uploadButton:hover:not(:disabled) {
    background: #218838;
}

.errorText {
    color: red;
    margin-top: 10px;
}

.successText {
    color: green;
    margin-top: 10px;
}

.videoUpdateForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.formGroup {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.formLabel {
    margin-bottom: 5px;
    font-weight: 500;
}

.formInput, .formSelect {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 1rem;
}

.formInput:focus, .formSelect:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}