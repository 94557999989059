/* Popup.css - Modern mobile-first implementation */
.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Use viewport width to ensure full coverage */
  height: 100vh; /* Use viewport height to ensure full coverage */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100; /* Ensure high z-index to be above other elements */
  backdrop-filter: blur(3px);
  animation: fadeIn 0.3s ease;
  box-sizing: border-box; /* Ensure padding is included in size calculations */
  padding: 0; /* Reset any padding */
  margin: 0; /* Reset any margins */
  overflow: hidden; /* Prevent scrolling while popup is open */
}

.popup-container {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 450px;
  text-align: center;
  position: relative;
  animation: scaleIn 0.3s ease;
  margin: 0 auto; /* Center horizontally */
  transform: translateX(0); /* Reset any transforms */
  left: 0; /* Reset any left positioning */
}

/* Remove the delete-popup class specific styling as it's not needed anymore */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
}

.popup-header h3 {
  margin: 0;
  font-size: 1.3rem;
  color: #333;
  font-weight: 600;
}

.close-icon {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.close-icon:hover {
  background-color: #f2f2f2;
  color: #333;
}

.popup-content {
  margin-bottom: 10px;
}

.popup-content p {
  margin: 15px 0 25px;
  line-height: 1.5;
  color: #555;
}

.popup-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.popup-button {
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.popup-button:hover {
  background-color: #f2f2f2;
  border-color: #ccc;
}

.popup-button.delete-one {
  background-color: #fff3f3;
  color: #e74c3c;
  border-color: #ffd8d8;
}

.popup-button.delete-one:hover {
  background-color: #ffe9e9;
  border-color: #ffcccc;
}

.popup-button.delete-all {
  background-color: #e74c3c;
  color: white;
  border-color: #e74c3c;
}

.popup-button.delete-all:hover {
  background-color: #d44333;
  border-color: #d44333;
}

.popup-button.cancel {
  background-color: transparent;
  border-color: transparent;
  margin-top: 5px;
}

.popup-button.cancel:hover {
  background-color: #f8f9fa;
}

/* Media queries for better responsiveness */
@media screen and (min-width: 480px) {
  .popup-container {
    width: 85%;
    padding: 25px;
  }
  
  .popup-actions {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .popup-button {
    flex: 1 0 calc(50% - 10px);
  }
  
  .popup-button.cancel {
    flex: 1 0 100%;
  }
}

@media screen and (min-width: 768px) {
  .popup-container {
    width: 450px;
  }
}

/* Fix for fixing the modal-overlay in ScheduleMakerPage */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}