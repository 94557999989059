.debugButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #f8f9fa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 9999;
  font-size: 20px;
}

.debuggerPanel {
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 20px;
  width: 90%;
  max-width: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.debuggerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.debuggerHeader h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.closeButton {
  background-color: transparent;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
}

.statusSection {
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.statusSection h4 {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #333;
}

.statusInfo {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.statusInfo p {
  margin: 5px 0;
  font-size: 12px;
}

.buttonGroup {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.actionButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.jsonContainer {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
  max-height: 150px;
}

.jsonContainer pre {
  margin: 0;
  font-size: 11px;
  white-space: pre-wrap;
}

.logSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow: hidden;
}

.logHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.logHeader h4 {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.clearButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.logContainer {
  flex: 1;
  overflow-y: auto;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
}

.emptyLog {
  color: #999;
  font-style: italic;
  text-align: center;
  margin: 20px 0;
}

.logEntry {
  font-size: 11px;
  padding: 4px 0;
  border-bottom: 1px solid #eee;
}

.logTime {
  color: #007bff;
  margin-right: 8px;
  font-weight: bold;
}

.logMessage {
  color: #333;
  word-break: break-word;
}

.troubleshootingSection {
  padding: 15px;
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
}

.troubleshootingSection h4 {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #333;
}

.troubleshootingSection ol {
  margin: 0;
  padding-left: 20px;
}

.troubleshootingSection li {
  font-size: 12px;
  margin-bottom: 5px;
}

@media (max-width: 576px) {
  .debuggerPanel {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    width: auto;
  }
}