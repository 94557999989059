.hours-worked-page {
  padding: 10px;
  max-width: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.hours-worked-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 15px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error-message {
  text-align: center;
  padding: 20px;
  background-color: #ffeeee;
  border-radius: 8px;
  margin: 20px 0;
  color: #d32f2f;
}

/* Media Queries */
@media (max-width: 480px) {
  .hours-worked-container {
    padding: 10px;
    box-shadow: none;
  }
}