.notificationPage {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.notificationSection {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.notificationInfo {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 2rem;
}

.subscribeButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 1rem;
}

.unsubscribeButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 1rem;
}

.testButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.localTestButton {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  margin: 0.5rem 0 0.5rem 0;
}

.formGroup {
  margin-bottom: 1rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.input, .textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.typeSelector {
  margin-bottom: 1.5rem;
}

.typeSelector label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.radioGroup label {
  display: flex;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
}

.radioGroup input[type="radio"] {
  margin-right: 0.5rem;
}

.note {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #6c757d;
}

h1, h2, h3 {
  color: #343a40;
}

ul {
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}