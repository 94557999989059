/* AdminPage.module.css */
.adminPageContainer {
  /* margin-left: 250px; Adjust based on your actual sidebar width */
  padding: 20px;
  background: #f4f7f6; /* Slightly off-white background like in the design */
  min-height: 100vh; /* Full height */
}

.pageTitle {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #2c3e50;
  position: relative;
  padding-bottom: 8px;
}

.pageTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 2px;
  background-color: #3498db;
  border-radius: 1px;
}

.userTable {
  max-width: 600px; /* Assuming the table width */
  margin: auto;
}

.userListContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 15px;
  max-width: 800px;
  margin: 0 auto;
}

.userRow {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin: 0 auto;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.userRow:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.userRowActive {
  border-left: 4px solid #3498db;
}

.profilePicPlaceholder {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  overflow: hidden;
  border: 2px solid #e1e6ea;
}

.userName {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-right: 10px;
}

.userStatus {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.statusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.03);
}

@media (max-width: 600px) {
  .userRow {
    padding: 10px 12px;
  }
  
  .profilePicPlaceholder {
    width: 38px;
    height: 38px;
    margin-right: 12px;
  }
  
  .userName {
    font-size: 15px;
  }
  
  .userStatus {
    gap: 8px;
  }
  
  .statusIcon {
    width: 28px;
    height: 28px;
  }
}