.gym-logo-container {
    width: 100%;
    text-align: center;
    z-index: 1000;
    padding: 15px 0;
    margin-bottom: 10px;
    background-color: #f8f9fa;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.gym-img-container {
    width: 130px;
    margin: auto;
    transition: transform 0.3s ease;
}

.gym-img-container:active {
    transform: scale(0.95);
}

.gym-logo {
    max-width: 100%;
    height: auto;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.1));
}